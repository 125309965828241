import baniotermostatico3 from '../baniotermostatico/baniotermostatico3.png'
import baniotermostatico2 from '../baniotermostatico/baniotermostatico2.png'
import baniotermostatico1 from '../baniotermostatico/baniotermostatico1.png'
import baniotermostatico4 from '../baniotermostatico/baniotermostatico4.png'

const baniotermostaticoImages = [
    baniotermostatico3,
    baniotermostatico2,
    baniotermostatico1,
    baniotermostatico4
]

export default baniotermostaticoImages