import desintegrador1 from '../desintegrador/desintegrador1.png'
import desintegrador2 from '../desintegrador/desintegrador2.png'
import desintegrador3 from '../desintegrador/desintegrador3.png'


const desintegradorImages = [
    desintegrador1,
    desintegrador2,
    desintegrador3
]

export default desintegradorImages