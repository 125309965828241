import disolutor1 from '../disolutor/disolutor1.jpg'
import disolutor2 from '../disolutor/disolutor2.jpg'
import disolutor3 from '../disolutor/disolutor3.jpg'
import disolutor4 from '../disolutor/disolutor4.jpg'

const disolutorImages = [
    disolutor1,
    disolutor2,
    disolutor3,
    disolutor4

]

export default disolutorImages