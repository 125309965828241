import durometro2 from '../durometro/durometro2.png'
import durometro3 from '../durometro/durometro3.png'
import durometro4 from '../durometro/durometro4.png'


const imageDurometro = [
    durometro2,
    durometro3,
    durometro4
]

export default imageDurometro